import GenericErrorView from 'Components/GenericError/GenericErrorView';
import Loading from 'Components/Loading/Loading';
import ReportsPageWrapper from 'Components/ReportsPageWrapper/ReportsPageWrapper';
import MFERoutes, { generateReportsPath } from 'Config/MFERoutes';
import { IReportsPageContext, ReportsPageContext } from 'Context/ReportsPageContext';
import { ITrackingContext, TrackingContext } from 'Context/TrackingContext';
import useReportsPage from 'Hooks/useReportsPage/useReportsPage';
import { ReportsPageStatus } from 'Hooks/useReportsPage/useReportsPage.types';
import useSelectedVendor from 'Hooks/useSelectedVendor/useSelectedVendor';
import React, { useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import ReportsTabComponent from './components/ReportsTabComponent/ReportsTabComponent';
import ReportsToggleComponent from './components/ReportsToggleComponent/ReportsToggleComponent';

export interface ReportsPageRouteParams {
  vendorId: string;
  tab: string;
  menuItem: string;
  country: string;
}

function ReportsPage(): React.ReactElement | null {
  const { vendorId, selectedCountry } = useSelectedVendor();

  const { status, menuItems, defaultMenuItem, selectedMenuItem, defaultTab, selectedTab } =
    useReportsPage();

  const reportsPageContextValue: IReportsPageContext | null = useMemo(() => {
    if (!!menuItems && !!selectedMenuItem && !!selectedTab) {
      return {
        menuItems,
        selectedMenuItem,
        defaultTab,
        selectedTab,
      };
    }
    return null;
  }, [menuItems, selectedMenuItem, defaultTab, selectedTab]);

  const trackingContextValue: ITrackingContext | null = useMemo(() => {
    if (!!selectedMenuItem && !!selectedTab) {
      const subType = selectedTab.trackingId;

      return {
        reportType: selectedMenuItem.trackingId,
        reportSubType: subType,
        tabName: selectedTab.trackingId,
        vendorId: null,
        vendorName: null,
        country: null,
        businessModel: null,
      };
    }
    return null;
  }, [selectedMenuItem, selectedTab]);

  if (status === ReportsPageStatus.VENDOR_NOT_SELECTED) {
    return <Redirect to={MFERoutes.MAIN_PAGE} />;
  }

  if (status === ReportsPageStatus.ERROR) {
    return (
      <ReportsPageWrapper>
        <GenericErrorView />
      </ReportsPageWrapper>
    );
  }

  if (status === ReportsPageStatus.LOADING) {
    return (
      <ReportsPageWrapper isLoading>
        <Loading type="container" />
      </ReportsPageWrapper>
    );
  }

  if (status === ReportsPageStatus.INVALID_MENU_ITEM) {
    return (
      <Redirect
        to={{
          pathname: generateReportsPath({
            vendorId,
            menuItem: defaultMenuItem.slug,
            country: selectedCountry,
          }),
        }}
      />
    );
  }

  if (status === ReportsPageStatus.INVALID_TAB) {
    return (
      <Redirect
        to={{
          pathname: generateReportsPath({
            vendorId,
            menuItem: selectedMenuItem.slug,
            tab: defaultTab.slug,
            country: selectedCountry,
          }),
        }}
      />
    );
  }

  return (
    <ReportsPageContext.Provider value={reportsPageContextValue}>
      <TrackingContext.Provider value={trackingContextValue}>
        <ReportsPageWrapper>
          {selectedMenuItem?.viewMode === 'VIEW' ? (
            <ReportsToggleComponent />
          ) : (
            <ReportsTabComponent />
          )}
        </ReportsPageWrapper>
      </TrackingContext.Provider>
    </ReportsPageContext.Provider>
  );
}

export default ReportsPage;
