import { useAuthenticationService } from 'admin-portal-shared-services';
import { Permission, PermissionRuleType, Workspace } from 'Domain/Workspaces';
import { useStore } from 'effector-react';
import useUserGroups from 'Hooks/useUserGroups/useUserGroups';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { getVendorsService } from 'Services/GetVendors/getVendorsService';
import { useGetWorkspaces } from 'Services/GetWorkspaces/useGetWorkspaces';
import LoggedUserStore from 'Store/LoggedUser/LoggedUserStore';
import { decryptData, encryptData } from 'Utils/encryption/encryption';
import { isManagerUser } from 'Utils/isManagerUser/isManagerUser';

export interface WorkspaceData {
  workspace?: Workspace;
  segment?: Permission;
  vendorId?: string;
  vendorName?: string;
  country?: string;
}

interface LocalStorageProps {
  workspaceId?: string;
  segmentId?: string;
  vendorId?: string;
  vendorName?: string;
  country?: string;
}

interface WorkspaceContextProps {
  workspaceData?: WorkspaceData;
  updateWorkspace: (newData: LocalStorageProps) => void;
  tempWorkspaceId?: string;
  setTempWorkspaceId: (id: string) => void;
  themeSelected?: string;
  setThemeSelected: (theme: string) => void;
}

export const WorkspaceContext = createContext({} as WorkspaceContextProps);
interface WorkspaceProviderProps {
  children: ReactNode;
}

export function WorkspaceProvider({ children }: Readonly<WorkspaceProviderProps>): JSX.Element {
  const authentication = useAuthenticationService();
  const hashKey = authentication.getUserId();
  const [workspaceData, setWorkspaceData] = useState<WorkspaceData>();
  const [tempWorkspaceId, setTempWorkspaceIdState] = useState<string | undefined>(undefined);
  const [themeSelected, setThemeSelectedState] = useState<string | undefined>(undefined);
  const { vendors: userVendors } = useStore(LoggedUserStore);

  const { data, isLoading } = useGetWorkspaces();
  const allWorkspaces = data?.workspaces;

  const userGroups = useUserGroups();
  const isManager = isManagerUser(userGroups);

  useEffect(() => {
    const updateData = async () => {
      if (allWorkspaces?.length === 0) {
        return;
      }

      const item = localStorage.getItem('workspaceSelected');
      if (item && hashKey) {
        try {
          const decryptedItem = await decryptData(hashKey, item);
          const obj = JSON.parse(decryptedItem);

          const getWorkspace = allWorkspaces?.find((workspace) => workspace.id === obj.workspaceId);
          const getSegment = getWorkspace?.permissions.find(
            (permission) => permission?.id === obj.segmentId
          );
          /* istanbul ignore if */
          if (!isLoading && (!getWorkspace || !getSegment)) {
            await setDefaultWorkspace();
          } else {
            updateWorkspace({
              segmentId: obj.segmentId,
              workspaceId: obj.workspaceId,
              vendorId: obj.vendorId,
              vendorName: obj.vendorName,
              country: obj.country,
            });
          }
        } catch (error) {
          console.error('Error decrypting data:', error);
          await setDefaultWorkspace();
        }
      }

      if (allWorkspaces && !item) {
        await setDefaultWorkspace();
      }
    };
    if (hashKey) {
      updateData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allWorkspaces, isLoading, hashKey]);

  async function fetchFirstVendor(defaultSegment?: Permission) {
    const vendorsList = await getVendorsService({
      customTotalElements: 101,
      country: defaultSegment?.country,
      serviceModel:
        defaultSegment?.rule?.type === PermissionRuleType.BUSINESS_MODEL
          ? defaultSegment?.rule?.value
          : undefined,
    });

    return vendorsList?.[0];
  }

  async function getDefaultVendor(defaultSegment?: Permission) {
    let defaultVendorId: string | undefined;
    let defaultVendorName: string | undefined;

    switch (defaultSegment?.rule?.type) {
      case PermissionRuleType.VENDOR_ID:
        defaultVendorId = defaultSegment?.rule?.value;
        break;
      case PermissionRuleType.BUSINESS_MODEL:
        if (isManager) {
          const { id, name } = await fetchFirstVendor(defaultSegment);
          defaultVendorId = id;
          defaultVendorName = name;
        } else {
          const defaultVendor = userVendors?.find(
            (vendor) => vendor.serviceModel === defaultSegment?.rule?.value
          );
          defaultVendorId = defaultVendor?.id;
          defaultVendorName = defaultVendor?.displayName;
        }
        break;
      case PermissionRuleType.COUNTRY:
        if (isManager) {
          const { id, name } = await fetchFirstVendor(defaultSegment);
          defaultVendorId = id;
          defaultVendorName = name;
        } else {
          const defaultVendor = userVendors?.find(
            (vendor) => vendor.country === defaultSegment?.country
          );
          defaultVendorId = defaultVendor?.id;
          defaultVendorName = defaultVendor?.displayName;
        }
        break;
      default:
        defaultVendorId = undefined;
        defaultVendorName = undefined;
        break;
    }

    return { defaultVendorId, defaultVendorName };
  }

  async function setDefaultWorkspace() {
    const defaultSegment = allWorkspaces?.[0]?.permissions[0];

    const { defaultVendorId, defaultVendorName } = await getDefaultVendor(defaultSegment);

    updateWorkspace({
      workspaceId: allWorkspaces?.[0]?.id,
      segmentId: defaultSegment?.id,
      vendorId: defaultVendorId,
      vendorName: defaultVendorName,
      country: defaultSegment?.country,
    });

    setWorkspaceData({
      workspace: allWorkspaces?.[0],
      segment: defaultSegment,
      vendorId: defaultVendorId,
      vendorName: defaultVendorName,
      country: defaultSegment?.country,
    });
  }

  const updateWorkspace = useCallback(
    async (newData: LocalStorageProps) => {
      /* istanbul ignore if */
      if (!hashKey) return;
      const getWorkspace = allWorkspaces?.find((workspace) => workspace.id === newData.workspaceId);
      const getSegment = getWorkspace?.permissions.find(
        (permission) => permission.id === newData.segmentId
      );

      try {
        const encryptedData = await encryptData(hashKey, JSON.stringify(newData));
        localStorage.setItem('workspaceSelected', encryptedData);
      } catch (error) {
        console.error('Error encrypting data:', error);
      }

      setWorkspaceData({
        workspace: getWorkspace,
        segment: getSegment,
        vendorId: newData.vendorId,
        vendorName: newData.vendorName,
        country: newData.country,
      });
    },
    [allWorkspaces, hashKey]
  );

  function setTempWorkspaceId(id: string) {
    setTempWorkspaceIdState(id);
  }

  function setThemeSelected(theme: string) {
    setThemeSelectedState(theme);
  }

  const contextValue = useMemo(
    () => ({
      workspaceData,
      tempWorkspaceId,
      themeSelected,
      updateWorkspace,
      setTempWorkspaceId,
      setThemeSelected,
    }),
    [tempWorkspaceId, themeSelected, updateWorkspace, workspaceData]
  );

  return <WorkspaceContext.Provider value={contextValue}>{children}</WorkspaceContext.Provider>;
}

export const useWorkspaceContext = (): WorkspaceContextProps => useContext(WorkspaceContext);
