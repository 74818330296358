const enUS: MessageMap = {
  Default: {
    ENABLED: 'Enabled',
    DISABLED: 'Disabled',
    NONE: 'None',
    LOADING: 'Loading',
    REQUIRED_FILE: 'File required',
    ERROR_UPDATE_IMAGE: 'Error updating image. Try again.',
  },
  ProductListPage: {
    TITLE: 'Products',
    CREATE_BUTTON: 'Create product',
  },
  CreateProductPage: {
    TITLE: 'Create product',
    SUCCESSFULLY_CREATED_TOAST: '{name} successfully created.',
    SUCCESSFULLY_CREATED_TOAST_ACTION_BUTTON: 'Show product',
    CREATE_PRODUCT_ERROR_MESSAGE_TOAST: 'Unable to create product.',
  },
  FooterForm: {
    CANCEL_BUTTON: 'Cancel',
    BACK_BUTTON: 'Back',
    SAVE_BUTTON: 'Save',
    CONTINUE_BUTTON: 'Continue',
  },
  CreateCategoryPage: {
    TITLE: 'Create a category',
  },
  ProductPage: {
    TITLE: 'Product details',
    TITLE_INFORMATION: 'Product information',
    TITLE_PRODUCT_TYPE: 'Product type',
    SUBTITLE_PRODUCT_TYPE: 'Details',
    TITLE_IMAGE: 'Product image',
    TITLE_VARIANTS: 'Variants',
    LABEL_NAME: 'Name',
    LABEL_VARIATION_SUFFIX: 'Variation suffix',
    LABEL_DESCRIPTION: 'Product description',
    LABEL_STATUS: 'Status',
    LABEL_MANUFACTURER: 'Manufacturer',
    LABEL_BRAND: 'Brand',
    LABEL_GTIN: 'Barcode',
    LABEL_SELECT_VALUE: 'Select a value',
    ATTRIBUTE_YES: 'Yes',
    ATTRIBUTE_NO: 'No',
    STATUS: 'Status',
    ENABLED: 'Enabled',
    DISABLED: 'Disabled',
    REQUIRED_FIELD: 'Required field',
    REQUIRED_FILE: 'File required',
    SUCCESS_UPDATE_PRODUCT_INFORMATION: 'Product information successfully updated.',
    ERROR_UPDATE_PRODUCT_INFORMATION: 'Unable to update product information. Try again.',
    SUCCESS_UPDATE_PRODUCT_TYPE: 'Product type successfully updated.',
    ERROR_UPDATE_PRODUCT_TYPE: 'Unable to update product type. Try again.',
    SUCCESS_UPDATE_PRODUCT_IMAGE: 'Product image was successfully updated.',
    ERROR_UPDATE_PRODUCT_IMAGE: 'Unable to update product image. Try again.',
    LANGUAGE_LABEL: 'Product language',
    DEFAULT_LANGUAGE_TRANSLATION_ALERT:
      'Some translations in {language} are incomplete. Complete them to ensure the product will be displayed correctly for customers.',
    SECONDARY_LANGUAGE_TRANSLATION_ALERT: `Complete translations for product {fields, select,
      name {name}
      description {description}
      name_description {name and description}
      other {fields}}
      to ensure they will be displayed correctly for customers.`,
  },
  CreateVariantsPage: {
    TITLE: 'Create variants',
    BREADCRUMB_TITLE: 'New variants',
  },
  VariantPage: {
    TITLE: 'Variant details',
    TITLE_INFORMATION: 'Variant information',
    TITLE_PRODUCT_TYPE: 'Product type',
    SUBTITLE_PRODUCT_TYPE: 'Details',
    TITLE_IMAGE: 'Variant image',
    TITLE_ITEMS: 'Items',
    LABEL_NAME: 'Name',
    LABEL_SKU: 'SKU',
    LABEL_STATUS: 'Status',
    LABEL_GTIN: 'Barcode',
    VARIANT: 'Variant',
    TABLE_NO_ITEMS: 'No items yet',
    NO_ITEMS_TO_SHOW: 'No items to show.',
    REQUIRED_FILE: 'File required',
    SUCCESS_UPDATE_VARIANT_IMAGE: 'Variant image was successfully updated.',
    ERROR_UPDATE_VARIANT_IMAGE: 'Unable to update variant image. Try again.',
    STATUS_MESSAGE: 'This variant will no longer be displayed in the BEES Customer app.',
    FORM_REQUIRED_FIELD: 'Required field.',
    SUCCESS_UPDATE_VARIANT_INFORMATION: 'Variant information successfully updated.',
    ERROR_UPDATE_VARIANT_INFORMATION: 'Unable to update variant information. Try again',
    SUCCESS_UPDATE_PRODUCT_TYPE: 'Variant attributes successfully updated.',
    ERROR_UPDATE_PRODUCT_TYPE: 'Unable to update variant attributes. Try again.',
    LANGUAGE_LABEL: 'Product language',
  },
  ItemDetailsPage: {
    TITLE: 'Item details',
    TITLE_PRODUCT_AND_VARIANT: 'Product and variant information',
    ITEM_INFORMATION: 'Item information',
    SKU: 'SKU',
    SKU_PLACEHOLDER: 'Enter a SKU',
    PRODUCT_VARIANT: 'Product / variant',
    SELLER: 'Seller',
    SELLER_ID: 'Seller ID',
    SELLER_ID_TOOLTIP: 'Identifier that distinguishes sellers on the platform.',
    SELLER_ITEM_ID: 'Seller item ID',
    SELLER_ITEM_ID_TOOLTIP: 'Internal identifier used to track items.',
    STATUS: 'Status',
    ENABLED: 'Enabled',
    DISABLED: 'Disabled',
    STATUS_MESSAGE: "This item won't be displayed on the BEES Customer and BEES Link.",
    PRODUCT_VARIANT_INFORMATION: 'Product and variant information',
    PRODUCT_LABEL: 'Product:',
    COUNT_VARIANTS_LABEL: 'Variants:',
    VARIANT_LABEL: 'Variant:',
    GTIN_LABEL: 'Barcode:',
    REQUIRED_FIELD: 'Required field',
    ITEM_TYPE: 'Item Type',
    SKU_MODAL_TITLE: 'Update SKU number?',
    SKU_MODAL_DESCRIPTION:
      'Changing the SKU number may cause data loss, inconsistencies, and search issues on BEES App.',
    SKU_MODAL_CANCEL: 'No, go back',
    SKU_MODAL_UPDATE: 'Update',
    FORM_REQUIRED_FIELD: 'Required field.',
    SUCCESS_UPDATE_ITEM_INFORMATION: 'Item information successfully updated.',
    ERROR_UPDATE_ITEM_INFORMATION: 'Unable to update item information. Try again',
    SUCCESS_UPDATE_ITEM_TYPE: 'Item type successfully updated.',
    ERROR_UPDATE_ITEM_TYPE: 'Unable to update item type. Try again',
    YES: 'Yes',
    NO: 'No',
    OPTIONAL: '(Optional)',
    ITEM_DETAILS: 'Details',
  },
  ImportFilePage: {
    IMPORT_PRODUCTS_TITLE: 'Import CSV and ZIP files',
    IMPORT_ITEMS_TITLE: 'Import CSV files',
    ERROR_MESSAGE_TOAST: 'Failed to import the files.',
    TRY_AGAIN_MESSAGE_TOAST: 'Please try again later',
    IMPORT_PRODUCTS_MESSAGE:
      'We support only ZIP and CSV files. Maximum 1 file per extension. Maximum size of {maxZipFileSize} per ZIP file and {maxCsvFileSize} per CSV file.',
    IMPORT_ITEMS_MESSAGE: 'We support only CSV files. Maximum size of {maxCsvFileSize} per file.',
    IMPORT_BUTTON: 'Import',
    DISABLED_BUTTON_TOOLTIP:
      'The .csv or .zip file is missing. Please upload the template with the products to finish the import.',
    DRAG_YOUR_FILE_HERE: 'Drag your file(s) here or ',
    UNSUPPORTED_FILE_MESSAGE:
      '{numberOfFileExtensions, plural, one {This is not a supported file. Please select a .csv file.} other {This is not a supported file. Please select a .zip or .csv file.}}',
    FILE_TOO_LARGE_MESSAGE: 'Your file exceeds {maxFileSize}. Please select a smaller file.',
    TITLE: 'Import file',
    SELECT_ENTITY: ' Select a entity',
    SELECT_OPERATION: 'Select operation',
    OPERATION_CRIATION: 'Creation',
    OPERATION_EDITION: 'Edition',
    CANCEL_BUTTON: 'Cancel',
  },
  TableHeader: {
    TOTAL_PRODUCTS:
      '{number, plural, =0 {Showing # products} one {Showing # product} other {Showing # products}}',
    LOADING: 'Loading ...',
  },
  CatalogListPage: {
    TITLE: 'Catalog',
    ITEMS_TAB: 'Items',
  },
  CreateProductButton: {
    BUTTON_LABEL: 'Create product',
  },
  CreateCategoryButton: {
    BUTTON_LABEL: 'Add new category',
  },
  CreateItemButton: {
    BUTTON_LABEL: 'Create items',
  },
  UploadFileButton: {
    BUTTON_LABEL: 'Import file',
  },
  AssignCategories: {
    TITLE: 'Assign products to categories',
    DESCRIPTION: 'Select all the categories that apply to your product:',
    STORE_LABEL: 'Store',
    TYPE_LABEL: 'Type',
    SELECTED_ITEMS: `{number, plural,
      one {1 category selected}
      other {{number} categories selected}
    }`,
    DESELECT_ALL: 'Deselect all',
    CANCEL_BUTTON: 'Cancel',
    ASSIGN_BUTTON: 'Assign products',
    CATEGORY_NAME_COLUMN: 'Category name',
    STATUS_COLUMN: 'Status',
    LEVEL_INDICATOR: 'Level {level}',
    CATEGORIES_ERROR_MESSAGE: 'Failed to load categories',
    CATEGORIES_LOADING_MESSAGE: 'Loading categories',
    CATEGORIES_MESSAGE: 'You must first select store and type in order to see categories',
    NO_STORES_MESSAGE: 'No stores available',
    NO_GROUPS_MESSAGE: 'No groups available',
    ERROR_MESSAGE: 'Unable to load category list. Try again',
  },
  ConfirmModal: {
    CONFIRM_TITLE: 'Confirm assignments?',
    PROCESSING_TITLE: 'Processing assignments',
    PROCESSED_TITLE: 'Assignment processed',
    DESCRIPTION: 'Review the assignments to categories below.',
    DETAILS: 'Details',
    STORE_LABEL: 'Store',
    STORE_TOOLTIP: 'Shows to which store this category belongs.',
    TYPE_LABEL: 'Type',
    TYPE_TOOLTIP:
      'Indicates whether the category is accessible through navigation or as a featured collection.',
    CATEGORIES: 'Categories',
    BACK_BUTTON: 'Go Back',
    CLOSE_BUTTON: 'Close',
    CONFIRM_BUTTON: 'Confirm',
    TRY_AGAIN_BUTTON: 'Try Again',
    SUCCESS_NOTIFY: 'You have successfully updated your products assignments.',
    ERROR_NOTIFY: 'Unable to assign products.',
    PARTIAL_SUCCESS_NOTIFY:
      '{partialCount} out of {completeCount} categories updated with product associations.',
  },
  BulkImportTable: {
    ENTITY: {
      ITEMS: 'Items',
      PRODUCTS: 'Products',
    },
    OPERATION: {
      POST: 'Creation',
      EDITION: 'Edition',
    },
    STATUS: {
      SUCCESS: 'Success',
      ERROR: 'Failed to download file',
    },
  },
  AssignProducts: {
    SELECTED_LABEL:
      '{count, plural, =0 {{count} products selected} one {{count} product selected  /} other {{count} products selected  /}}',
    ASSIGN_BUTTON_LABEL: 'Assign products',
    CANCEL_BUTTON_LABEL: 'Cancel',
    DESELECT_ALL_BUTTON: 'Deselect all',
  },
  ItemsTable: {
    SELLERS_LOADING_ERROR_TOAST: 'An error occurred while loading the sellers.',
  },
  StatusFilterTab: {
    ENABLED: 'Enabled',
    DISABLED: 'Disabled',
  },
  SellerFilterTab: {
    SEARCH_SELLER: 'Search seller',
    SHOWING_RESULTS: 'Showing {results} results',
    NO_RESULTS: 'No results. Review the spelling or use other terms.',
    LOADING: 'Loading',
    MAX_SELLERS_TOOLTIP: 'The maximum number of sellers you can select is 50.',
  },
  Popover: {
    FILTER: 'Filter',
    APPLIED: 'applied.',
    STATUS: 'Status',
    SELLER: 'Seller',
    CLEAR_ALL: 'Clear all',
    CANCEL: 'Cancel',
    APPLY: 'Apply',
    PRODUCT_TYPE: 'Product type',
    BRAND: 'Brand',
    MANUFACTURER: 'Manufacturer',
    SELECT_SELLER: 'You should select at least one seller.',
  },
  Filter: {
    FILTER: 'Filter',
    SEARCH_PLACEHOLDER: {
      SKUS: 'Enter a SKU number',
      GTINS: 'Search by Barcode',
      VENDOR_ITEM_IDS: 'Search by Seller item ID',
    },
    SEARCH_OPTIONS: {
      SKUS: 'SKU',
      GTINS: 'Barcode',
      VENDOR_ITEM_IDS: 'Seller item ID',
    },
    FILTERED_BY: 'Filtered by:',
    STATUS: 'Status:',
    ENABLED: 'Enabled',
    DISABLED: 'Disabled',
    SELLER: 'Seller:',
    CLEAR_ALL: 'Clear all',
    PRODUCT_TYPE: 'Product type',
    BRAND: 'Brand',
    MANUFACTURER: 'Manufacturer',
    SEARCH_PLACEHOLDER_PRODUCT: 'Search by product name',
  },
  ModalPath: {
    TITLE: 'Define category path',
    ALL_CATEGORIES_TITLE: 'All Categories',
    DESCRIPTION: 'Select the path leads to this category.',
    BUTTON_TRIGGER: 'Define path',
    BUTTON_TRIGGER_SELECTED: 'One path selected',
    ADD_CATEGORY_BUTTON: 'Add category here',
    DEFINE_PATH_BUTTON: 'Define path',
    CATEGORY_NAME_PLACEHOLDER: 'Your category name here',
    GO_BACK_BUTTON: 'Go back',
    ADDED_CATEGORY_BUTTON: 'Category added',
    CATEGORY_LIST_TITLE: 'Category path preview',
    LEVEL: '(Level {level})',
    STORE: 'Store',
    TYPE: 'Type',
    STORE_TOOLTIP: 'Shows to which seller this category belongs.',
    TYPE_TOOLTIP: 'The audience that will be exposed to each category',
    EMPTY_CATEGORIES_DESCRIPTION: 'No categories added yet. You can add your category here.',
  },
  CategoryListPage: {
    TITLE: 'Category',
    TABLE: {
      SHOWING_N_CATEGORIES: 'Showing {count} categories',
      EDIT_CATEGORY_POSITIONS: 'Edit Category Positions',
      EDITING_CATEGORY_POSITIONS_TITLE: 'Change category position',
      EDITING_CATEGORY_POSITIONS_DESCRIPTION:
        'The order in which you place the categories here is the same order that will appear to the customer.',
      EDITING_CATEGORY_POSITIONS_SAVE_CHANGES: 'Save changes',
      EDITING_CATEGORY_POSITIONS_CANCEL: 'Cancel',
      EMPTY_TABLE: 'There are no categories created',
      ERROR_MESSAGE: 'Error while loading the categories',
      POSITION: 'Position',
      CATEGORY_NAME: 'Category name',
      SUBCATEGORIES: 'Subcategories',
      PRODUCTS: 'Products',
      STATUS: 'Status',
      LEVEL: 'Level',
      ENABLED: 'Enabled',
      DISABLED: 'Disabled',
      SUBCATEGORIES_TOOLTIP: 'Column shows the number of subcategories inside each category.',
      PRODUCTS_TOOLTIP: 'Column shows the number of products inside each category.',
      STATUS_TOOLTIP: 'Defines the visibility of this category.',
    },
    TABS: {
      ERROR_LOADING_GROUPS: 'There was an issue loading the category groups.',
      NO_GROUPS_AVAILABLE: 'There are no category groups available.',
    },
    CATEGORY_POSITIONS_SUCCESSFULLY_EDITED_TOAST: 'Category positions were successfully edited',
    CATEGORY_POSITIONS_FAILED_EDITED_TOAST:
      'An error occurred while attempting to edit the category positions',
  },
  CreateCategoryForm: {
    STEP_ONE_TITLE: 'Category information',
    DESCRIPTION: 'Enter main information.',
    DISCARD_MODAL_TITLE: 'Discard category creation?',
    CREATED_CATEGORY_TOAST: '{category} was successfully created.',
    ERROR_CREATING_CATEGORY_TOAST: 'Error creating category {category}.',
  },
  CategoryInformationStep: {
    CATEGORY_DETAILS_TITLE: 'Category details',
    CATEGORY_CLASSIFICATION_TITLE: 'Category classification',
    CATEGORY_NAME_LABEL: 'Name',
    NAME_PLACEHOLDER: 'Enter a name',
    TYPE_LABEL: 'Type',
    STATUS: 'Status',
    NAME_ERROR_MESSAGE: 'Please, fill this field',
    TYPE_PLACEHOLDER: 'Select a type',
    NO_CATEGORY_TYPE: 'No category type',
    ERROR_UPDATING_CATEGORY_INFORMATION: 'Unable to update category information. Try again',
  },
  CategoryDetailsPage: {
    TITLE: {
      CATEGORY_DETAILS: 'Category details',
      CATEGORY_CLASSIFICATION: 'Category classification',
      PRODUCT_LIST: 'Product list',
    },
    LABEL: {
      NAME: 'Name',
      IMAGE: 'Image',
      STATUS: 'Status',
    },
    TOAST: {
      CATEGORY_SUCCESSFULLY_UPDATED: 'Category successfully updated',
      CATEGORY_UPDATE_ERROR: 'Error updating category',
      CATEGORY_PRODUCT_SUCCESSFULLY_ADDED:
        '{productsCount, plural, one {1 product was successfully added to this category.} other {# products were successfully added to this category.}}',
      CATEGORY_PRODUCT_ERROR: 'Error adding products to this category',
    },
    ERROR_MESSAGE: {
      NAME: 'This field is required',
    },
    PLACEHOLDER: {
      NAME: 'Enter a name',
    },
    TOOLTIP: {
      NAME: 'How it appears on BEES Customer app.',
      STATUS: 'Indicates whether the category is available to customers.',
    },
    BUTTON: {
      ADD_PRODUCTS: 'Add products',
      UNASSIGN_PRODUCTS: 'Unassign products',
      SAVE_CHANGES: 'Save changes',
      CANCEL: 'Cancel',
    },
    SELECT: {
      MORE_OPTIONS: 'More options',
      CHANGE_PRODUCT_POSITION: 'Change product position',
      UNASSIGN_PRODUCTS: 'Unassign products',
    },
    FILTER: {
      SEARCH_PLACEHOLDER: 'Search',
    },
  },
  CategoryProductsTable: {
    PAGE_SIZE_OPTIONS: 'Items per page: {pageSize}',
    EMPTY_MESSAGE: 'There are no products in this category.',
    ERROR_MESSAGE: 'Error while loading the products.',
    PRODUCT_SELECTED_DISPLAY_TEXT: 'product selected',
    PRODUCTS_SELECTED_DISPLAY_TEXT: 'products selected',
    UNASSIGN_DIALOG: {
      TITLE: 'Remove products from this category?',
      DESCRIPTION:
        'Keep in mind that products that are not added to any category will be unavailable to customers.',
      CANCEL: ' No, go back',
      CONFIRM: 'Yes, remove',
    },

    REORDER_DIALOG: {
      TITLE: 'Confirm product positions?',
      DESCRIPTION: 'Review changes to product positions.',
      ITEM: '{newPosition}º of {totalProducts}: ',
      CANCEL: 'Go back',
      CONFIRM: 'Confirm',
    },
    TOAST: {
      CATEGORY_PRODUCT_SUCCESSFULLY_REARRANGED:
        '{productsCount} products were successfully rearranged.',
      CATEGORY_PRODUCT_REORDER_ERROR: 'Error reordering products from this category',
      CATEGORY_PRODUCT_SUCCESSFULLY_UNASSIGNED: `
      {
        productsCount,
        plural,
          one {{productsCount, number} product was successfully removed from this category.}
          other {{productsCount, number} products were successfully removed from this category.}
      }`,
      CATEGORY_PRODUCT_UNASSIGN_ERROR: 'Error unassigning products from this category',
    },
  },
  ProductTypeDetailsForm: {
    OPTIONAL: '(Optional)',
  },
  CategoryProductsTableHeaders: {
    PRODUCT_TITLE: 'Product title',
    PRODUCT_TYPE: 'Product type',
    BRAND: 'Brand',
    MANUFACTURER: 'Manufacturer',
    ACTIONS: 'Actions',
  },
  CategoryProductsStep: {
    STEP_TWO_TITLE: 'Product list',
    EMPTY_STATE_DESCRIPTION: 'There are no products in this category.',
    BUTTON_ADD_PRODUCTS: 'Add products',
    MODAL_TITLE: 'Select products',
    BUTTON_BACK: 'Back',
    BUTTON_ADD_SELECTED_PRODUCTS: 'Add selected products',
    ROW_SELECTED: 'product selected',
    ROWS_SELECTED: 'products selected',
    BUTTON_DESELECT_ALL: 'Deselect all',
    NO_PRODUCTS_TO_SHOW: 'No products to show.',
    NO_PRODUCTS_TO_SHOW_MODAL: 'No products to show. Start by creating some.',
    PAGINATION_OF: 'of',
  },
  CategorySummaryStep: {
    CATEGORY_DETAILS_SECTION: 'Category details',
    NAME: 'Name',
    CATEGORY_CLASSIFICATION: 'Category classification',
    TYPE: 'Type',
    TYPE_INFO:
      'Indicates whether the category is accessible through navigation or as a featured collection.',
    CATEGORY_PATH: 'Category path',
    CATEGORY_PATH_INFO: 'Shows the navigation path that leads to this category.',
    STATUS: 'Status',
    STATUS_INFO: 'Indicates whether this category is available to customers.',
    ENABLED: 'Enabled',
    DISABLED: 'Disabled',
    PRODUCTS: 'Products',
    REVIEW_PRODUCTS: 'Review products',
    CATEGORY_IMAGE_PREVIEW: 'Category image preview',
    PRODUCTS_ADDED: '{count} Added',
    MISSING_TRANSLATION_WARNING:
      'No manual translation for {selectedLanguage} has been set up. Some product information will be displayed in {defaultLanguage} by default.',
    ATTRIBUTES_BLOCK: {
      PRODUCT_DETAILS_TITLE: 'Product details',
      VARIANT_DETAILS_TITLE: 'Variant details',
      MANUFACTURER_LABEL: 'Manufacturer',
      BRAND_LABEL: 'Brand',
      BARCODE_LABEL: 'Barcode',
      STATUS_LABEL: 'Status',
    },
  },
  CountrySelector: {
    COUNTRY_NOT_SUPPORTED: 'The country ({country}) is not supported by your user',
  },
  UseCountry: {
    NO_SUPPORTED_COUNTRIES: 'User has no supported countries',
  },
  QuickPreviewComponent: {
    HIDE: 'Hide',
  },
  ProductInformationStep: {
    STEP_TITLE: 'Product information',
    STEP_DESCRIPTION: 'Insert the main information',
    QUICK_PREVIEW_NAME: 'Show name in {defaultLanguage}',
    QUICK_PREVIEW_DESCRIPTION: 'Show product description in {defaultLanguage}',
    DEFAULT_LANGUAGE: {
      LABEL: 'Default language',
      TOOLTIP: 'The zone requires the product to be created in the default language.',
    },
    PRODUCT_TYPE: {
      LABEL: 'What type of product do you want to create?',
      SINGLE_VARIANT_TITLE: 'Single variant',
      SINGLE_VARIANT_DESCRIPTION: 'A product will have one variant',
      MULTIPLE_VARIANT_TITLE: 'Multiple variants',
      MULTIPLE_VARIANT_DESCRIPTION: 'This product will have variants (package, sizes, quantity).',
    },
    PRODUCT_NAME: {
      LABEL: 'Product name',
      PLACEHOLDER: 'Enter name',
    },
    PRODUCT_IMAGE: {
      LABEL: 'Product image',
      DESCRIPTION_MESSAGE: 'Thumbnails must be JPG, PNG format and must be less than 1MB',
      BROWSE_FILES_BUTTON: 'Browse Files',
    },
    PRODUCT_DESCRIPTION: {
      LABEL: 'Product description',
      PLACEHOLDER: 'Enter text here',
      AI_DESCRIPTION_BUTTON: 'Generate AI description',
      AI_DESCRIPTION_TOOLTIP: 'Make sure to review it before continuing.',
      AI_DESCRIPTION_INFO:
        'Provide a product name and image to enable AI product description generation.',
    },
    VARIANT_NAME: {
      LABEL: 'Variant name',
      HINT: 'Also known as EAN, UPC or GTIN.',
      PLACEHOLDER: 'E.g: 6-pack, 12-pack, box, unit',
    },
    STATUS: {
      LABEL: 'Status',
      ENABLED: 'Enabled',
      DISABLED: 'Disabled',
    },
    MANUFACTURER: {
      LABEL: 'Manufacturer',
      PLACEHOLDER: 'No manufacturer associated',
      EMPTY_MESSAGE: 'No manufacturers to select',
    },
    BRAND: {
      LABEL: 'Brand',
      PLACEHOLDER: 'Select a brand',
      EMPTY_MESSAGE: 'No brands to select',
    },
    BARCODE: {
      LABEL: 'Barcode',
      PLACEHOLDER: 'Example: 78934300131458',
      HINT: 'Also known as EAN, UPC or GTIN',
      OPTIONAL_LABEL: '(Optional)',
    },
    ERROR: {
      REQUIRED_FIELD: 'Please, fill this field',
      INVALID_FILE_TYPE: 'Please, select a .jpg or .png file.',
      MAX_FILE_SIZE: 'Image size exceeds the maximum limit of 1MB',
      MAX_CHARACTERS_LIMIT:
        'Please fill this field with a maximum of {maxCharactersLimit} characters',
    },
    GenericFilterTab: {
      NO_RESULTS: 'No results. Review the spelling or use other terms.',
      LOADING: 'Loading',
      MAX_TOOLTIP: 'The maximum number of {callback} you can select is {max}.',
    },
  },
  ProductTypeStep: {
    STEP_TITLE: 'Product Type',
    STEP_DESCRIPTION: 'Fill the product details',
  },
  ProductTranslationStep: {
    STEP_TITLE: 'Translation',
    STEP_DESCRIPTION: 'Enter additional language',
    PAGE_TITLE: 'Product translation',
    PAGE_DESCRIPTION:
      'There are users in {selectedCountry} who speak {languages}. Enter translations to ensure that they will access the product in these languages. You can add or edit translations after creating the product.',
    BLOCK_DEFAULT_LABEL: 'Default language:',
    BLOCK_TRANSLATION_LABEL: 'Translation language:',
    BLOCK_FIELD_PRODUCT_NAME: 'Product name',
    BLOCK_FIELD_PRODUCT_DESCRIPTION: 'Product description',
    BLOCK_FIELD_VARIANT_NAME: 'Variant name',
    BLOCK_FIELD_PLACEHOLDER: 'Enter a translation',
  },
  ProductTranslationModal: {
    TITLE: 'Continue without translation?',
    DESCRIPTION: "The following languages won't be added:",
    PARTIAL_DESCRIPTION: 'Only languages with all fields filled out will be considered.',
    ADD_OR_EDIT_DESCRIPTION: 'You can add or edit translations after creating the product.',
    CONTINUE_BUTTON: 'Yes, continue',
    GO_BACK_BUTTON: 'No, go back',
  },
  ProductSummaryStep: {
    STEP_TITLE: 'Summary',
    STEP_DESCRIPTION: 'Review & create',
  },
  ProductTypeModal: {
    TITLE: 'Select a product type',
    SUBTITLE: 'Review the product type details before selecting it',
    TITLE_LIST: 'Product types',
    TITLE_DETAILS: 'Details',
    SELECTED_TYPE: 'Selected type',
    SELECTED_TYPE_RESUME: 'Selected type: {selectedType}',
    SELECT_BUTTON: 'Select',
    SELECT_BUTTON_TITLE: 'Select a Product Type',
    GO_BACK_BUTTON: 'Go back',
    BACK_BUTTON: 'Back',
    NO_DATA_AVAILABLE: 'No data available',
    NO_ATTRIBUTES: 'No attributes created for selected product type.',
    NO_SELECTED_TYPE: 'Select a product type to preview its details here.',
  },
  ProductInstructions: {
    CSV_FILE_TEMPLATE: 'CSV file template',
    CSV_FILE_TEMPLATE_DESCRIPTION: 'Use this spreadsheet template to fill the information.',
    INSTRUCTIONS_TOOLTIP: 'You need to select the Product Type to show import instructions.',
    DOWNLOAD_TOOLTIP: 'You need to select the Product Type to download the template.',
    SHOW_INSTRUCTIONS: 'Show instructions',
    DOWNLOAD_CSV_TEMPLATE: 'Download template',
    PRODUCT_IMAGES: 'Product images',
    PRODUCT_IMAGES_DESCRIPTION:
      'Rename the images with the product ID and compact them in a ZIP file.',
    DOWNLOAD_IMAGES_TEMPLATE: 'Download example',
  },
  ProductImageInstructionsModal: {
    TITLE: 'Image instructions',
    FILE_SIZE_AND_FORMAT: 'Compacted file size should be up to {maxZipFileSize} and ZIP format.',
    IMAGE_FORMAT: 'Image format must be JPEG or PNG.',
    IMAGE_RESOLUTION:
      'Image resolution must be {imageResolution} or less than {maxImageFileSizePerFile}.',
    IMAGE_NAME: 'Image name must be its Barcode.',
    IMPORT_TIME: 'Import time may vary due to file size.',
    GOT_IT: 'Got it',
  },
  ProductCsvInstructionsModal: {
    TITLE: 'CSV file instructions',
    DOWNLOAD_DATA_INSTRUCTIONS:
      'Download the <link>data instructions documentation</link> to check the details for filling the csv template.',
    FILE_SIZE: 'File size must be up to {maxCSVFileSize}.',
    FILE_FORMAT: 'File format must be CSV and the values in the row must be separated by a comma.',
    PRODUCTS_MUST_BELONG: 'The products must belong to the same product type.',
    EVEN_IF_SOME_ROWS:
      'Even if some rows are incorrectly filled, the correct ones will still be processed.',
    MANDATORY_COLUMNS: 'Mandatory columns have asterisk (*).',
    IMPORT_TIME: 'Import time may vary due to file size.',
    SAME_GROUP_ID:
      'It is essencial that all variants share the same <strong>group ID</strong> in order to link them to a product.',
    GOT_IT: 'Got it',
  },
  ItemsCsvInstructionsModal: {
    TITLE: 'CSV file instructions',
    FILE_SIZE: 'File size should be up to {maxCSVFileSize}.',
    FILE_FORMAT: 'File format must be CSV and the values in the row must be separated by a comma.',
    EVEN_IF_SOME_ROWS:
      'Even if some rows are incorrectly filled, the correct ones will still be processed.',
    MANDATORY_COLUMNS: 'Mandatory columns are marked with a "*".',
    IMPORT_TIME: 'The import could take longer, depending on the file size.',
    GOT_IT: 'Got it',
    SHOW_FULL_INSTRUCTIONS: 'Show full instructions',
  },
  Components: {
    ACTIVITY_HISTORY_TITLE: 'Activity history',
    ACTIVITY_HISTORY_TITLE_LAST_EDITION_BY: 'Last edition by',
    ACTIVITY_HISTORY_TITLE_CREATED_BY: 'Created by',
    ACTIVITY_HISTORY: '{username} on {date}',
    ERROR_404_TITLE: 'Mmm… Looks like this page is gone',
    ERROR_404_DESCRIPTION: "It looks like this page wasn't found or doesn't exist.",
    ERROR_404_BUTTON: 'Back',
    ERROR_500_TITLE: 'Something went wrong',
    SAVE_CHANGES: 'Save changes',
    CANCEL: 'Cancel',
    LABEL_MANUFACTURER: 'Manufacturer',
    LABEL_BRAND: 'Brand',
    YES: 'Yes',
    NO: 'No',
    SELECT_AN_OPTION: 'Select an option',
    SELECTED: 'Selected',
    OPTIONAL: '(Optional)',
    REQUIRED_FIELD: 'Required field',
    INVALID_NUMBER_ERROR: 'Please enter a valid number',
  },
  SelectByEntity: {
    PRODUCT_TYPE: 'Product type',
    PRODUCT_TYPE_DESCRIPTION: 'The products in the CSV file must match the selected type.',
    SELLER: 'Seller',
  },
  CancelModal: {
    TITLE: 'Cancel import?',
    DESCRIPTION: 'All information will be lost.',
    NO_GO_BACK: 'No, go back',
    YES_CANCEL: 'Yes, cancel',
  },
  Status: {
    PROCESSING: 'processing',
    DONE: 'imported',
    FAILED: 'failed to import',
    PARTIALLY_UPDATED: 'partially imported',
    SUCESSFULLY_UPDATED: '"{fileName}" successfully imported.',
    WARNING_MESSAGE:
      '"{fileName}" has been partially imported. Please check the errors in the CSV file available on the action column and try again.',
  },
  RecentActivity: {
    TITLE: 'Recent activity',
    MODAL_BODY:
      'The audit log stores data for 30 days. Access all activities in BEES audit logs application.',
    SHOW_ALL: 'Show all',
    COPY_MESSAGE_SUCCESS: 'ID successfully copied to the clipboard.',
    EMPTY_MESSAGE:
      'There are no logs registered yet. Once an update is made, it will reflect here.',
    ERROR_MESSAGE: 'Unable to load audit log data. Please try again later.',
    SHOW_DETAILS: 'Show details',
    HIDE_DETAILS: 'Hide details',
    REQUESTOR: 'Requestor:',
    COPY_USER_ID: 'Copy user ID',
    SHOW_RECENT_ACTIVITY: 'Show recent activity',
    MORE_DETAILS_MODIFIED: `{action, select,
      UPDATE_INFORMATION {{quantity} more information modified}
      UPDATE_ATTRIBUTES {{quantity, plural, one {# more attribute modified} other {# attributes modified}}}
      other {{quantity} more modified}
    }`,
    ENTITY_CREATE_TITLE: `{entity, select,
      PRODUCTS {Product creation}
      VARIANTS {Variant creation}
      ITEMS {Item creation}
      CATEGORIES {Category creation}
      other {{entity} creation}
    }`,
    ENTITY_CREATE_DESCRIPTION: `{entity, select,
      PRODUCTS {Product "{value}" was created}
      VARIANTS {Variant "{value}" was created}
      ITEMS {Item "{value}" was created}
      CATEGORIES {Category "{value}" was created}
      other {{entity} "{value}" was created}
    }`,
    ENTITY_REMOVE_IMAGE_TITLE: `{entity, select,
      PRODUCTS {Product image removed}
      VARIANTS {Variant image removed}
      ITEMS {Item image removed}
      CATEGORIES {Category image removed}
      other {{entity} image removed}
    }`,
    ENTITY_REMOVE_IMAGE_DESCRIPTION: `{entity, select,
      PRODUCTS {Product image was removed}
      VARIANTS {Variant image was removed}
      ITEMS {Item image was removed}
      CATEGORIES {Category image was removed}
      other {{entity} image was removed}
    }`,
    ENTITY_UPDATE_FIELD_TITLE: `{entity, select,
      PRODUCTS {{field, select,
        name {Product name update}
        description {Product description update}
        status {Product status update}
        manufacturer {Product manufacturer update}
        brand {Product brand update}
        image {Product image update}
        attributes {Product attributes update}
        other {Product information update}
      }}
      VARIANTS {{field, select,
        name {Variant name update}
        status {Variant status update}
        image {Variant image update}
        attributes {Variant attributes update}
        other {Variant information update}
      }}
      ITEMS {{field, select,
        sku {Item SKU update}
        status {Item status update}
        image {Item image update}
        attributes {Item attributes update}
        other {Item information update}
      }}
      CATEGORIES {{field, select,
        name {Category name update}
        status {Category status update}
        image {Category image update}
        attributes {Category attributes update}
        other {Category information update}
      }}
      CATEGORY_TYPES {{field, select,
        status {Category status update}
        other {Category {field} update}
      }}
      other {{entity} information update}
    }`,
    ENTITY_UPDATE_FIELD_DESCRIPTION: `{entity, select,
      PRODUCTS {{field, select,
        name {Product name changed from "{previous}" to "{current}"}
        description {Product description changed from "{previous}" to "{current}"}
        status {Product status changed from "{previous}" to "{current}"}
        manufacturer {Product manufacturer changed from "{previous}" to "{current}"}
        brand {Product brand changed from "{previous}" to "{current}"}
        image {Product image was updated}
        other {Product {field} changed from "{previous}" to "{current}"}
      }}
      VARIANTS {{field, select,
        name {Variant name changed from "{previous}" to "{current}"}
        status {Variant status changed from "{previous}" to "{current}"}
        image {Variant image was updated}
        other {Variant {field} changed from "{previous}" to "{current}"}
      }}
      ITEMS {{field, select,
        sku {Item SKU changed from "{previous}" to "{current}"}
        status {Item status changed from "{previous}" to "{current}"}
        image {Item image was updated}
        other {Item {field} changed from "{previous}" to "{current}"}
      }}
      CATEGORIES {{field, select,
        name {Category name changed from "{previous}" to "{current}"}
        status {Category status changed from "{previous}" to "{current}"}
        image {Category image was updated}
        other {Category {field} changed from "{previous}" to "{current}"}
      }}
      CATEGORY_TYPES {{field, select,
        status {{subEntityName} status changed from "{previous}" to "{current}"}
        other {Category {field} changed from "{previous}" to "{current}"}
      }}
      other {{entity} {field} changed from "{previous}" to "{current}"}
    }`,
    ENTITY_ASSIGNMENT_TITLE: `{action, select,
      ASSIGN_PRODUCT {Products added}
      UNASSIGN_PRODUCT {Products removed}
      ASSIGN_CATEGORY {Categories added}
      UNASSIGN_CATEGORY {Categories removed}
      other {{action} assignment}
    }`,
    ENTITY_ASSIGNMENT_DESCRIPTION: `{action, select,
      ASSIGN_PRODUCT {Product "{subEntityName}" was added to "{entityName}"}
      UNASSIGN_PRODUCT {Product "{subEntityName}" was removed from "{entityName}"}
      ASSIGN_CATEGORY {Category "{subEntityName}" was added to "{entityName}"}
      UNASSIGN_CATEGORY {Category "{subEntityName}" was removed from "{entityName}"}
      other {{action} assignment}
    }`,
    ENTITY_REORDER_TITLE: `{action, select,
      REORDER_PRODUCT {Products order update}
      REORDER_CATEGORY {Category order update}
      REORDER_SUB_CATEGORY {Category (level {entityLevel}) order update}
      other {{action} order update}
    }`,
    ENTITY_REORDER_DESCRIPTION: `{action, select,
      REORDER_PRODUCT {Product "{subEntityName}" position was changed from "{previous}" to "{current}"}
      REORDER_CATEGORY {Category "{subEntityName}" position was changed from "{previous}" to "{current}"}
      REORDER_SUB_CATEGORY {Category "{subEntityName}" position was changed from "{previous}" to "{current}"}
      other {{entity} were reordered}
    }`,
  },
};

export default enUS;
